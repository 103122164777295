@import '../theme-bootstrap';

@for $i from 0 through 100 {
  .width-#{$i} {
    width: $i * 1%;
  }
}

.elc-comparison-module {
  padding: 0 20px;
  @media #{$cr19-xsmall-up} {
    padding: 0 28px;
  }
  @media #{$cr19-small-up} {
    padding: 0 36px;
  }
  @media #{$cr19-medium-up} {
    padding: 0 44px;
  }
  @media #{$cr19-large-up} {
    padding: 0 64px;
  }
  @media #{$cr19-xlarge-up} {
    padding: 0 72px;
  }
  margin-bottom: 40px;
  &__heading {
    margin-bottom: 16px;
  }
  &__wrapper {
    display: block;
    @media #{$cr19-large-up} {
      display: flex;
      flex-flow: row nowrap;
    }
    &-labels {
      display: none;
      @media #{$cr19-large-up} {
        display: block;
        width: 140px;
      }
      @media #{$cr19-xlarge-up} {
        width: 200px;
      }
    }
  }
  &__products {
    display: flex;
    flex-flow: row nowrap;
    @media #{$cr19-large-up} {
      width: calc(100% - 140px);
    }
    @media #{$cr19-xlarge-up} {
      width: calc(100% - 200px);
    }
    &-pinned {
      background: $cr19-bg-light;
      position: relative;
      width: calc(50% - 10px);
      @media #{$cr19-medium-up} {
        width: calc(1 / 3 * 100% - 20px);
      }
      @media #{$cr19-typography-xlarge-up} {
        width: calc(1 / 4 * 100% - 30px);
      }
    }
    &-carousel {
      margin-bottom: 0;
      width: calc(50% + 10px);
      @media #{$cr19-medium-up} {
        width: calc(2 / 3 * 100% + 20px);
      }
      @media #{$cr19-typography-xlarge-up} {
        width: calc(3 / 4 * 100% + 30px);
      }
      &--full {
        width: 100%;
      }
      .elc-comparison-module__content {
        width: calc(100% - 20px);
        @media #{$cr19-medium-up} {
          width: calc(50% - 30px);
        }
        @media #{$cr19-typography-xlarge-up} {
          width: calc(1 / 3 * 100% - 40px);
        }
        &:not(:first-of-type) {
          display: none;
        }
        &.slick-slide {
          display: block;
        }
      }
    }
  }
  &-pin {
    background: url('#{$base-theme-path}img/icons/src/push-pin.svg') no-repeat;
    background-position: center;
    background-size: contain;
    height: 18px;
    position: absolute;
    top: 10px;
    width: 18px;
    #{$rdirection}: 10px;
    @media #{$cr19-large-up} {
      height: 24px;
      width: 24px;
    }
  }
  &__content {
    padding: 0 10px;
    @media #{$cr19-xlarge-up} {
      padding: 0 20px;
    }
    &.slick-slide {
      border-#{$rdirection}: 1px solid $color-grey;
      &:last-of-type {
        border-#{$rdirection}: none;
      }
    }
  }
  &__image {
    display: block;
    margin: 0 auto;
    width: auto;
    height: 140px;
    @media #{$cr19-small-up} {
      height: 210px;
    }
    @media #{$cr19-medium-up} {
      height: 230px;
    }
    @media #{$cr19-xlarge-up} {
      height: 250px;
    }
    @media #{$cr19-typography-xlarge-up} {
      height: 270px;
    }
  }
  &__image-link {
    display: block;
    padding-bottom: 40px;
  }
  &__link.disabled {
    pointer-events: none;
  }
  &__sku-select {
    cursor: pointer;
    font-size: 1rem;
    margin-bottom: 20px;
    position: relative;
    &.opened {
      .elc-comparison-module__sku-select--options {
        display: block;
      }
    }
    &--header {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &--button {
      background-color: transparent;
      border: none;
    }
    &--wrapper {
      align-items: center;
      border: 1px solid $color-black;
      color: $cr19-text;
      cursor: pointer;
      display: flex;
      height: 45px;
      justify-content: space-between;
      padding: 0 14px;
      font-family: $helvetica-regular-font-family;
      font-size: 15px;
      @media #{$cr19-typography-small-up} {
        font-size: 1rem;
      }
      .elc-comparison-module__arrow-icon {
        background-color: $color-black;
        cursor: pointer;
        height: 10px;
        mask: url('#{$base-theme-path}img/icons/src/arrow-8.svg') center center no-repeat;
        width: 10px;
        &.opened {
          transform: rotate(180deg);
        }
      }
    }
  }
  &__shade-select {
    &--header {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &--swatch {
      height: 15px;
      margin-#{$rdirection}: 15px;
      min-width: 15px;
      width: 15px;
    }
    &--name {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  &__sku-select--options {
    display: none;
    position: absolute;
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    background: $color-white;
    z-index: 1;
    max-height: 250px;
    overflow: auto;
    li {
      border: 1px solid $color-grey;
      border-top: 0;
      cursor: pointer;
      padding: 13px;
      display: flex;
      flex-direction: row;
      align-items: center;
      &:hover {
        background-color: $cr19-bg-light;
      }
    }
  }
  &__header {
    &--product-name {
      margin-top: 270px;
      min-height: 105px;
      @media #{$cr19-xlarge-up} {
        margin-top: 290px;
      }
      @media #{$cr19-typography-xlarge-up} {
        margin-top: 310px;
        min-height: 79px;
      }
    }
    &--rating {
      min-height: 34px;
    }
    &--price {
      min-height: 172px;
    }
    &--benefit,
    &--skin-type,
    &--ingredients,
    &--skin-concern,
    &--timeofuse,
    &--form,
    &--finish,
    &--coverage {
      @media #{$cr19-typography-small-up} {
        min-height: 70px;
      }
      @media #{$cr19-typography-xlarge-up} {
        min-height: 65px;
      }
    }
  }
  &__product-name {
    color: $cr19-text;
    font-size: 15px;
    line-height: 23px;
    margin-bottom: 20px;
    min-height: 92px;
    @media #{$cr19-xsmall-up} {
      font-size: 1rem;
      line-height: 24px;
      margin-bottom: 10px;
      min-height: 96px;
    }
    @media #{$cr19-typography-xlarge-up} {
      min-height: 70px;
    }
  }
  &__name-link {
    color: $color-cl-light-black-2;
    display: block;
    font-family: $helvetica-bold-font-family;
  }
  &__rating {
    align-items: center;
    display: flex;
    height: 16px;
    margin-bottom: 20px;
    &-average,
    &-stars {
      background: url('#{$base-theme-path}img/icons/src/star.svg') repeat-x;
      background-size: 14px;
      height: 14px;
    }
    &-average {
      filter: brightness(0.85);
      margin-#{$rdirection}: 4px;
      width: 70px;
    }
    &-stars {
      filter: brightness(0.12);
    }
    &-total {
      color: $color-cl-dark-grey;
      font-size: 0.75rem;
      margin-top: 1px;
    }
  }
  &__price {
    align-items: baseline;
    color: $cr19-text;
    display: flex;
    font-family: $helvetica-bold-font-family;
    font-size: 1rem;
    flex-flow: row nowrap;
    margin-bottom: 20px;
    &--old {
      color: $color-input-gray;
      font-family: $helvetica-light-font-family;
      margin-#{$ldirection}: 10px;
      text-decoration: line-through;
    }
  }
  &__add-to-bag {
    .basic_cta_partial {
      &.elc-button {
        min-width: 0;
        text-transform: capitalize;
        width: 100%;
        &.elc-button--disabled {
          cursor: not-allowed;
          text-transform: capitalize;
          width: 100%;
        }
      }
    }
    &--wrapper {
      margin-bottom: 20px;
    }
  }
  &__info {
    margin-bottom: 20px;
    min-height: 70px;
    @media #{$cr19-typography-small-up} {
      min-height: 50px;
    }
    @media #{$cr19-typography-xlarge-up} {
      min-height: 45px;
    }
  }
  &__title {
    @media #{$cr19-large-up} {
      display: none;
    }
  }
  &__copy {
    color: $color-black;
    font-family: $helvetica-regular-font-family;
    font-size: 13px;
    @media #{$cr19-large-up} {
      font-size: 1rem;
    }
  }
  .slick-list {
    padding-#{$rdirection}: 20px;
    @media #{$cr19-medium-up} {
      padding-#{$rdirection}: 60px;
    }
    @media #{$cr19-typography-xlarge-up} {
      padding-#{$rdirection}: 110px;
    }
  }
  .active-content {
    display: grid;
  }
}

.spp__container {
  .elc-comparison-module {
    padding: 0;
  }
}
